import React from 'react'
import Hero from '../components/hero'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import ServicePreview from '../components/service-preview'

class ServicesIndex extends React.Component {
  render() {
    const services = get(this, 'props.data.allContentfulService.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Our Services" />
        <Hero title="Our Services" />
        <ServicePreview services={services} />
      </Layout>
    )
  }
}

export default ServicesIndex

export const pageQuery = graphql`
  query ServiceIndexQuery {
    allContentfulService {
      nodes {
        title
        cover {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

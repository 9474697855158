import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Container from './container'
import * as styles from './service-preview.module.css'

const ServicePreview = ({ services }) => {
  if (!services) return null
  if (!Array.isArray(services)) return null

  return (
    <Container>
      <ul className={styles.serviceList}>
        {services.map((item, i) => {
          return (
            <li key={`service-${i}`}>
              <Link to={`/services`} className={styles.link}>
                <GatsbyImage alt="" image={item.cover.gatsbyImageData} />
                <h2 className={styles.title}>{item.title}</h2>
              </Link>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.body.childMarkdownRemark.html,
                }}
              />
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default ServicePreview
